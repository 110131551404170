<template>
  <div class="container">
    <div class="header">
      <Header></Header>
    </div>
    <div class="nav">
      <Nav :navData="navList" :isActive="nav_id"></Nav>
    </div>
    <div class="content">
      <div class="wCenter">
        <!-- 导航栏 -->
        <div class="navigation">
          <span class="index">首页 > </span>
          <span>{{ title }}</span>
          <span> > {{ data.post_title }}</span>
        </div>
        <!-- 左侧 -->
        <div class="news-notice">
          <div class="news">
            <div class="info-title">{{ data.post_title }}</div>
            <div class="info-date">{{ data.published_times }}</div>
            <div class="info-content" v-html="data.post_content"></div>

            <div
              v-if="data.more.files"
              style="width: 100%; height: 41px; margin-top: 20px"
            >
              <span style="font-size: 20px; font-weight: 700; color: #333333"
                >资料下载 :
              </span>

              <span
                v-for="(file_item, file_index) in data.more.files"
                :key="file_index"
                style="
                  font-size: 18px;
                  color: #059e96;
                  border-bottom: 1px solid #059e96;
                  margin-left: 20px;
                "
                ><a
                  :href="file_item.url"
                  target="__blank"
                  style="color: #059e96"
                  >《{{ file_item.name }}》</a
                ></span
              >
            </div>
          </div>
          <!-- 右侧 -->

          <div class="notices-right">
            <!-- 推荐阅读 -->
            <div class="notices">
              <div class="news-img">
                <img src="../assets/images/hot.png" alt="" />
                <div class="news-more" @click="jumpMore()">更多>></div>
                <div class="notices-title">推荐阅读</div>
              </div>
              <div class="title-list">
                <div
                  v-for="(hot_item, hot_index) in top"
                  :key="hot_index"
                  class="title-item"
                  @click="jumpRecomment(hot_item.id)"
                >
                  <div class="title">{{ hot_item.post_title }}</div>
                  <div class="date">
                    [{{ hot_item.create_time | formatDate }}]
                  </div>
                </div>
              </div>
            </div>
            <!-- 热门资讯 -->
            <div class="hot-notices">
              <div class="news-img">
                <img src="../assets/images/hot.png" alt="" />
                <router-link to="/news?news_id=9&nav_id=8"
                  ><div class="news-more">更多>></div></router-link
                >
                <div class="notices-title">热门资讯</div>
              </div>
              <div class="title-list">
                <div
                  v-for="(top_item, top_index) in hot"
                  :key="top_index"
                  class="title-item"
                  @click="jumpNews(top_item.id, 9, 8)"
                >
                  <div class="title">{{ top_item.post_title }}</div>
                  <div class="date">
                    [{{ top_item.create_time | formatDate }}]
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 底部 -->
    <div>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import { getInfo, getMechanism, getNav } from "@/http/api/home/index";
import Header from "../components/header.vue";
import Nav from "../components/nav.vue";
import Footer from "../components/footer.vue";
export default {
  data() {
    return {
      id: "",
      news_id: "",
      nav_id: "",
      nav: [], //导航
      title: "",
      navList: "",
      hot: [], //热门分类
      top: [], //推荐分类
      data: [],
    };
  },
  filters: {
    formatDate: function (value) {
      let date = new Date(value * 1000);
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return MM + "-" + d;
    },
  },
  components: {
    Header,
    Nav,
    Footer,
  },
  watch: {
    $route: {
      handler: function (val) {
        this.getNav();
        this.id = val.query.id;
        this.news_id = val.query.news_id;
        this.nav_id = val.query.nav_id;
        this.getMechanism();
        this.getInfo(this.id, this.news_id, this.nav_id);
      },
    },
  },
  created() {
    this.getNav();
    this.id = this.$route.query.id;
    this.news_id = this.$route.query.news_id;
    this.nav_id = this.$route.query.nav_id;
    this.getMechanism();
    this.getInfo(this.id, this.news_id, this.nav_id);
  },
  methods: {
    getMechanism() {
      getMechanism({ news_id: this.news_id, nav_id: this.nav_id }).then(
        (res) => {
          this.title = res.data.data.title;
          this.nav = res.data.data.nav;
        }
      );
    },
    jump(query) {
      var url = "/news?" + query;
      this.$router.push({ path: url });
    },
    getNav() {
      getNav().then((res) => {
        this.navList = res.data.data;
      });
    },
    //跳转详情
    jumpInfo(id) {
      var url = "/info?id=" + id;
      this.$router.push({ path: url });
    },
    getInfo(id, news_id, nav_id) {
      getInfo({ id: id, news_id: news_id, nav_id: nav_id }).then((res) => {
        this.hot = res.data.other.hot;
        this.top = res.data.other.top;
        this.data = res.data.data;
      });
    },
    jumpRecomment(id) {
      var url =
        "/info?id=" +
        id +
        "&news_id=" +
        this.news_id +
        "&nav_id=" +
        this.nav_id;
      this.$router.push({ path: url });
    },
    jumpMore() {
      var url = "/news?"+
      "news_id=" + this.news_id + "&nav_id=" + this.nav_id;
      this.$router.push({ path: url });
    },
    jumpNews(id, news_id, nav_id) {
      var url = "/info?id=" + id + "&news_id=" + news_id + "&nav_id=" + nav_id;
      this.$router.push({ path: url });
    },
  },
};
</script>

<style lang="less">
.article_Box left {
  display: none;
}
.content {
  background: #f7f7f7;
  padding-top: 20px;
  padding-bottom: 20px;
  .navigation {
    height: 44px;
    background: #ffffff;
    font-size: 16px;
    color: #666666;
    line-height: 44px;
    padding-left: 11px;
    .index {
      cursor: pointer;
    }
  }
  .news-notice {
    width: 100%;
    display: flex;
    padding-top: 20px;
    .news {
      width: 800px;
      background: #ffffff;
      padding: 0 20px 20px 20px;
      .info-title {
        font-size: 24px;
        font-weight: 700;
        color: #333333;
        text-align: center;
        padding: 16px 0px;
        overflow: hidden;
      }
      .info-date {
        font-size: 18px;
        color: #666666;
        text-align: center;
      }
      .info-content {
        font-size: 18px;
        line-height: 34px;
        margin-top: 28px;
        h1 {
          display: none;
        }
        label {
          display: none;
        }
        a{
          color: #004789;
        }
      }
    }
    .notices-right {
      width: 380px;
      height: auto;
      .notices {
        width: 380px;
        height: 429px;
        margin-left: 20px;
        background: #ffffff;
        padding: 20px;
        overflow: hidden;
        .news-img {
          width: 100%;
          position: relative;
          img {
            width: 100%;
            height: 100%;
          }
          .news-more {
            position: absolute;
            right: 0px;
            top: 10px;
            font-size: 12px;
            font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
            font-weight: 400;
            text-align: left;
            color: #005a69;
            cursor: pointer;
          }
          .notices-title {
            font-size: 16px;
            font-family: MicrosoftYaHei, MicrosoftYaHei-Bold;
            font-weight: 700;
            color: #ffffff;
            position: absolute;
            top: 5px;
            left: 18px;
          }
        }
        .title-list {
          cursor: pointer;
          .title-item {
            margin-top: 21px;
            width: 100%;
            height: 19px;
            display: flex;
            .title {
              width: 280px;
              font-size: 14px;
              font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
              color: #333333;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .title:hover {
              color: #059e96;
            }
            .date {
              margin-left: 10px;
              font-size: 14px;
              font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
              color: #999999;
              line-height: 19px;
            }
          }
        }
      }
      .hot-notices {
        width: 380px;
        height: 429px;
        margin-left: 20px;
        background: #ffffff;
        padding: 20px;
        margin-top: 20px;
        overflow: hidden;
        .news-img {
          width: 100%;
          position: relative;
          img {
            width: 100%;
            height: 100%;
          }
          .news-more {
            position: absolute;
            right: 0px;
            top: 10px;
            font-size: 12px;
            font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
            font-weight: 400;
            text-align: left;
            color: #005a69;
          }
          .notices-title {
            font-size: 16px;
            font-family: MicrosoftYaHei, MicrosoftYaHei-Bold;
            font-weight: 700;
            color: #ffffff;
            position: absolute;
            top: 5px;
            left: 18px;
          }
        }
        .title-list {
          .title-item {
            cursor: pointer;
            margin-top: 21px;
            width: 100%;
            height: 19px;
            display: flex;
            .title {
              width: 280px;
              font-size: 14px;
              font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
              color: #333333;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .title:hover {
              color: #059e96;
            }
            .date {
              margin-left: 10px;
              font-size: 14px;
              font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
              color: #999999;
              line-height: 19px;
            }
          }
        }
      }
    }
  }
}
</style>
